module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ankocorp","path":"/preview","previews":true,"pages":[{"type":"Blog_page","match":"/blog","path":"/","component":"/working_dir/src/templates/blog.js"},{"type":"Blog_post","match":"/blog/:uid","path":"/","component":"/working_dir/src/templates/blog-post.js"},{"type":"Portfolio","match":"/portfolio","path":"/","component":"/working_dir/src/pages/portfolio.js"},{"type":"Success_story","match":"/portfolio/:uid","path":"/","component":"/working_dir/src/templates/success-story.js"},{"type":"Landing_page","match":"/landing/:uid","path":"/","component":"/working_dir/src/templates/landing-page.js"},{"type":"Content_page","match":"/:uid","path":"/","component":"/working_dir/src/templates/content-page.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-28207096-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"ankocorp.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mautic/gatsby-browser.js'),
      options: {"plugins":[],"hostUrl":"https://info.ankocorp.com","head":false,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0},
    },{
      plugin: require('../plugins/gatsby-plugin-optinmonster/gatsby-browser.js'),
      options: {"plugins":[],"userId":"75106","accountId":"84468"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
